<template>
    <div class="popup">
        <van-popup v-model="show" @click-overlay="close">
            <div class="content">
                <div class="header">
                    <div>{{ $t("DepositDetails.DepositDetails") }}</div>
                    <div class="close">
                        <i class="iconfont icon-s-cuowu-guanbi" @click="close"></i>
                    </div>
                </div>
                <div class="main-content">
                    <img class="time-icon" src="@/assets/deposit/icon_time.png" />
                    <div class="WaitingForPayment">{{ detail.rechargeOrderState }}</div>
                    <div v-if="detail.usdtAmt" class="money" @click="onCopy(detail.usdtAmt + 'USDT')">
                        <span>{{ detail.usdtAmt }}USDT</span>
                        <span class="iconfont icon-fuzhiwenjian"></span>
                    </div>
                    <div v-else class="money" @click="onCopy(CurrencyType + detail.orderAmt)">
                        <span class="val">{{ CurrencyType }}{{ detail.orderAmt }}</span>
                        <span class="iconfont icon-fuzhiwenjian"></span>
                    </div>
                    <div v-if="detail.usdtAmt" class="exchangeRate">
                        <span>{{ $t("DepositDetails.ExchangeRate") + ' U1 : ' + CurrencyType + (detail.usdtRate || 0) + '，'
                        }}</span>
                        <span>{{ $t("deposit.Deposit") }}</span>
                        <span class="val">{{ CurrencyType }}{{ detail.orderAmt }}</span>
                    </div>
                    <div class="bills">
                        <div class="bills-item">
                            <div>{{ $t("DepositDetails.DepositMethod") }}</div>
                            <div>{{ detail.payChannelType }}</div>
                        </div>
                        <div class="bills-item">
                            <div>{{ $t("DepositDetails.CreationTime") }}</div>
                            <div>{{ detail.createTime }}</div>
                        </div>
                        <div class="bills-item">
                            <div>{{ $t("DepositDetails.OrderNumber") }}</div>
                            <div @click="onCopy(detail.orderNo)">
                                <div class="val">{{ detail.orderNo }}</div>
                                <span class="iconfont icon-fuzhiwenjian"></span>
                            </div>
                        </div>
                        <div v-if="detail.usdtAmt" class="bills-item">
                            <div>{{ $t("DepositDetails.TransactionRemark") }}</div>
                            <div @click="onCopy(detail.memberInfoId)">
                                <div class="val">{{ detail.memberInfoId }}</div>
                                <span class="iconfont icon-fuzhiwenjian"></span>
                            </div>
                        </div>
                        <div v-if="detail.cashAddress && detail.rechargeOrderState == 'WaitPay' && step == 1"
                            class="bills-item qrcode-box">
                            <div style="width: 1.38rem;" class="qrcode-wrap">
                                <img class="qrcode-icon" src="@/assets/deposit/icon_Transfer.png" />
                                <div class="qrcode-canvas">
                                    <canvas ref="qrcodeCanvas"></canvas>
                                </div>
                            </div>
                        </div>
                        <div v-if="detail.cashAddress && detail.rechargeOrderState == 'WaitPay' && step == 1"
                            class="bills-item qrcode-item">
                            <div>TRC-20：</div>
                            <div @click="onCopy(detail.cashAddress)">
                                <div class="val">{{ detail.cashAddress }}</div>
                                <span class="iconfont icon-fuzhiwenjian"></span>
                            </div>
                        </div>
                        <div v-else-if="detail.usdtAmt" class="bills-item">
                            <div>TRC-20</div>
                            <div @click="onCopy(detail.cashAddress)">
                                <div class="val">{{ detail.cashAddress }}</div>
                                <span class="iconfont icon-fuzhiwenjian"></span>
                            </div>
                        </div>
                        <div v-if="detail.paymentCode && detail.usdtAmt" class="bills-item">
                            <div>{{ $t("DepositDetails.hashAddress") }}</div>
                            <div>
                                <div class="line val"
                                    @click="onNavTo(('https://tronscan.io/#/transaction/' + detail.paymentCode), 'href')">
                                    {{ detail.paymentCode }}</div>
                                <span class="iconfont icon-fuzhiwenjian"
                                    @click="onCopy('https://tronscan.io/#/transaction/' + detail.paymentCode)"></span>
                            </div>
                        </div>
                    </div>
                    <div v-if="((detail.rechargeOrderState == 'WaitPay' && step == 2) || detail.rechargeOrderState == 'Paying' || detail.rechargeOrderState == 'Fail') && detail.usdtAmt"
                        class="page_title">{{ $t('DepositDetails.hashAddress') }}</div>
                    <div v-if="((detail.rechargeOrderState == 'WaitPay' && step == 2) || detail.rechargeOrderState == 'Paying' || detail.rechargeOrderState == 'Fail') && detail.usdtAmt"
                        class="page_input">
                        <a-input v-model="hashAddress"
                            :placeholder="$t('DepositDetails.pleasetEnterHashAddress')"></a-input>
                    </div>
                    <div v-if="detail.usdtAmt" class="tips">
                        <div>{{ $t("DepositDetails.DepositTips1") }}</div>
                        <div>{{ $t("DepositDetails.DepositTips2") }}</div>
                        <div>{{ $t("DepositDetails.DepositTips3") }}</div>
                    </div>
                </div>
                <div v-if="detail.rechargeOrderState == 'WaitPay' && step == 1 && detail.usdtAmt" class="btns">
                    <div class="btn cancel-btn" @click="onCancel">{{ $t("DepositDetails.cancelBtn") }}</div>
                    <div class="btn confirm-btn" @click="onConfirm">{{ $t("DepositDetails.TransferredBtn") }}</div>
                </div>
                <div v-if="detail.rechargeOrderState == 'WaitPay' && step == 2 && detail.usdtAmt" class="btns">
                    <div class="btn confirm-btn" @click="onContinue">{{ $t("DepositDetails.ContinueBtn") }}</div>
                </div>
                <div v-if="(detail.rechargeOrderState == 'Paying' || detail.rechargeOrderState == 'Fail') && detail.usdtAmt" class="btns">
                    <div class="btn confirm-btn" @click="onContinue">{{ $t("DepositDetails.ContinueBtn") }}</div>
                </div>
            </div>
        </van-popup>
        <confirmPopup ref="confirmPopup" :orderNo="detail.orderNo" @close="close"></confirmPopup>
    </div>
</template>

<script>
import { Toast } from 'vant'
import QRCode from 'qrcode'
import confirmPopup from './confirmPopup.vue'
import { WriteUsdtDepositOrderHash } from '@/api/deposit'
export default {
    name: 'depositDetail',
    components: { confirmPopup },
    data() {
        return {
            show: false,
            isFocus: 0,
            hashAddress: '',
            canCallHandleRecharge: true,
            detail: {},
            step: 1
        }
    },
    props: {
        details: {
            type: Object,
            default: () => {
                return {
                    createTime: '',
                    dataGrandComputeState: '',
                    firstOrManyType: '',
                    fistRechargeRecommenderState: '',
                    giftAmt: 0,
                    id: '',
                    ipAddress: '',
                    memberInfoId: '',
                    note: '',
                    orderAmt: 0,
                    orderNo: '',
                    payChannelId: '',
                    payChannelType: '',
                    payFailDec: null,
                    payTime: null,
                    payUrl: null,
                    paymentCode: '',
                    realityAmt: 0,
                    receiveTime: null,
                    rechargeLevelState: '',
                    rechargeOrderState: '',
                    recommenderMemberInfoId: '',
                    salesPersonId: '',
                    updateTime: '',
                    usdtAmt: 0,
                    usdtRate: 0,
                    userAgent: null,
                    yesNoType: '',
                    hashAddress: ''
                }
            }
        }
    },
    methods: {
        close() {
            this.show = false
            this.step = 1
        },
        open() {
            this.show = true
            this.hashAddress = ''
            this.$nextTick(() => {
                this.detail = this.details
                if (this.detail.cashAddress) {
                    this.$nextTick(() => {
                        this.generateQRCode(this.detail.cashAddress)
                    })
                }
            })
        },
        generateQRCode(link) {
            QRCode.toCanvas(this.$refs.qrcodeCanvas, link, error => {
                if (error) console.error(error)
            })
        },
        onCancel() {
            this.$refs.confirmPopup.open()
        },
        onConfirm() {
            this.step = 2
        },
        onContinue() {
            if (this.canCallHandleRecharge) {
                this.canCallHandleRecharge = false
                if (!this.hashAddress) {
                    Toast(this.$t('DepositDetails.pleasetEnterHashAddress'))
                    this.canCallHandleRecharge = true
                    return
                }
                const params = {
                    memberHash: this.hashAddress,
                    orderNo: this.detail.orderNo
                }
                WriteUsdtDepositOrderHash(params).then(res => {
                    Toast(res.message)
                    if (res.code === 0 && !this.canCallHandleRecharge) {
                        this.$emit('onContinue')
                        this.close()
                    }
                }).finally(() => {
                    this.canCallHandleRecharge = true
                })
            }
        }
    }
}
</script>

<style scoped lang="scss">
@import '@/common/styles/viriables.scss';
.popup {
    .van-popup {
        background: transparent;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;

        .content {
            border-radius: 12px;
            background: $bg-popup-color;
            width: 500px;
            max-height: 80vh;
            height: 700px;
            box-sizing: border-box;
            position: relative;
            overflow: auto;
            padding-bottom: 30px;
            display: flex;
            flex-direction: column;
        }

        .header {
            display: flex;
            height: 1rem;
            padding-left: .46rem;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-top: .1rem;

            &>div:first-child {
                font-size: .32rem;
                font-weight: bold;
                color: #fff;
            }

            .close {
                color: $font-iconfont-color;
                font-size: .36rem;
                font-weight: bold;
                height: 1rem;
                width: 1rem;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
            }
        }

        .main-content {
            padding: 0 .28rem;
            flex: 1;
            padding-bottom: 1rem;
            overflow: auto;

            .time-icon {
                width: 1rem;
                height: 1rem;
                margin: 0 auto;
                display: block;
            }

            .WaitingForPayment {
                color: $font-theme-color;
                font-size: .24rem;
                text-align: center;
                margin-top: .28rem;
            }

            .money {
                display: flex;
                align-items: center;
                justify-content: center;
                color: #fff;
                font-size: .52rem;

                &>span:last-child {
                    font-size: .42rem;
                    margin-left: .2rem;
                    color: $font-theme-color;
                }
            }

            .exchangeRate {
                display: flex;
                align-items: center;
                justify-content: center;
                color: #999;

                .val {
                    color: #FFAA09;
                    margin-left: .08rem;
                }
            }

            .bills {
                border-top: .01rem dashed #4D4841;
                border-bottom: .01rem dashed #4D4841;
                padding: .2rem 0;
                margin: .24rem 0;

                .bills-item {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: .1rem 0;

                    &>div {
                        width: 50%;
                        color: $font-label-color;
                        font-size: .24rem;
                        line-height: .32rem;
                        text-align: left;
                    }

                    &>div:last-child {
                        font-size: .22rem;
                        color: #fff;
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;
                        line-height: .28rem;
                        width: 50%;
                        text-align: right;

                        .iconfont {
                            color: $font-theme-color;
                            margin-left: .15rem;
                        }

                        .line {
                            color: $font-theme-color;
                        }

                        .val {
                            width: calc(100% - .4rem);
                            word-break: break-all;
                        }
                    }

                    &.qrcode-box {
                        justify-content: center;
                        border-top: .01rem dashed #666;
                        padding-top: .28rem;
                        margin-top: .22rem;

                        .qrcode-wrap {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            flex-direction: column;

                            .qrcode-icon {
                                width: .58rem;
                                height: .58rem;
                                border-radius: 50%;
                                margin-bottom: .2rem;
                            }

                            .qrcode-canvas {
                                background: #ffffff;
                                width: 1.72rem;
                                height: 1.72rem;
                                overflow: hidden;
                                position: relative;

                                canvas {
                                    position: absolute;
                                    top: 50%;
                                    left: 50%;
                                    transform: translate(-50%, -50%);
                                    width: 115% !important;
                                    height: 115% !important;
                                }
                            }
                        }
                    }

                    &.qrcode-item {
                        justify-content: center;

                        &>div:first-child {
                            span-align: right;
                            width: auto;
                        }

                        .val {
                            span-align: left;
                        }
                    }
                }
            }

            .page_title {
                color: #fff;
                font-weight: bold;
                font-size: .28rem;
                padding-top: .08rem;
                padding-bottom: .2rem;
            }

            .page_input {
                ::v-deep input::-webkit-outer-spin-button {
                    -webkit-appearance: none;
                }

                ::v-deep input::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                }

                ::v-deep input[type='number'] {
                    -moz-appearance: textfield;
                }

                ::v-deep .ant-input {
                    background-color: $bg-input-color !important;
                    border-radius: 0.14rem;
                    font-size: 0.22rem;
                    height: 0.85rem;
                    padding: 0px 0.24rem !important;
                    color: #e3e3e3;
                    border: .02rem solid $border-input-color !important;
                    padding-right: 2.8rem !important;

                    &:focus {
                        background: $bg-input-act-color !important;
                        border-color: $border-active-color !important;
                    }

                    &::-webkit-input-placeholder {
                        /* WebKit browsers */
                        font-size: .22rem;
                        color: $font-placeholder-color;
                        opacity: .7;
                        font-weight: 100;
                    }

                    &:-moz-placeholder {
                        /* Mozilla Firefox 4 to 18 */
                        font-size: .22rem;
                        color: $font-placeholder-color;
                    }

                    &::-moz-placeholder {
                        /* Mozilla Firefox 19+ */
                        font-size: .22rem;
                        color: $font-placeholder-color;
                    }

                    &:-ms-input-placeholder {
                        /* Internet Explorer 10+ */
                        font-size: .22rem;
                        color: $font-placeholder-color;
                    }

                }

                ::v-deep .ant-input-suffix {
                    .extra {
                        color: #ff7300;
                        padding-right: .24rem;
                    }

                    .noBonus {
                        color: #f3df00;
                        padding-right: .24rem;
                    }
                }
            }

            .tips {
                color: $font-label-color;
                font-size: .22rem;
                padding-bottom: .1rem;
                text-align: left;
            }
        }

        .btns {
            width: 100%;
            position: absolute;
            height: .9rem;
            left: 0;
            bottom: 0;
            display: flex;
            align-items: flex-start;
            justify-content: space-around;
            padding: 0 .18rem;
            background-color: #1d2027;
            padding-top: .1rem;

            .btn {
                width: 43%;
                height: .7rem;
                line-height: .7rem;
                text-align: center;
                font-size: .26rem;
                border-radius: .12rem;

                &.cancel-btn {
                    border: .01rem solid $font-theme-color;
                    color: $font-theme-color;
                }

                &.confirm-btn {
                    background: $bg-btn-color;
                    color: $font-btn-color;
                }
            }
        }
    }

    &.mobile {
        // width: 100% !important;
        // height: 100% !important;

        .van-popup {
            &.van-popup--center {
                top: 16vh;
                bottom: 0;
                left: 0;
                transform: translate3d(0, 0, 0);
                width: 100% !important;
                // height: 100% !important;
            }
        }

        .content {
            width: 100% !important;
            height: 100% !important;
            max-height: 100%;
            border-radius: 12px 12px 0 0 !important;
        }
    }
}
</style>
