<template>
    <div class="popup" :class="classObj">
        <van-popup :value="show" @click-overlay="close" @open="open">
            <div class="content">
                <div class="header">
                    <div>{{ $t('turntable.title') }}</div>
                    <div class="close">
                        <i class="iconfont icon-s-cuowu-guanbi" @click="close"></i>
                    </div>
                </div>
                <div class="box">
                    <div class="total">
                        <div class="total1">
                            <div>
                                <span>{{ $t('turntable.totalTips1') }}</span>
                                <span class="num">{{ memberLuckyAmt }}</span>
                            </div>
                            <!-- <view>{{$t('turntable.detail')}}</view> -->
                        </div>
                        <div v-if="luckyWheelReward == 'everydayFirstrecharge'" class="total2">
                            <span>{{ $t('turntable.totalTips5') }}</span>
                            <span class="num">{{ bettingGainLucky }}</span>
                            <span>{{ $t('turntable.totalTips6') }}</span>
                        </div>
                        <div v-else class="total2">
                            <span>{{ $t('turntable.totalTips2') }}</span>
                            <span class="num">1</span>
                            <span>{{ $t('turntable.totalTips3') }}</span>
                            <span class="num">{{ bettingGainLucky }}</span>
                            <span>{{ $t('turntable.totalTips4') }}</span>
                        </div>
                    </div>
                    <div class="turntable_content">
                        <div class="type">
                            <div v-for="(item, index) in turntableList" :key="index" class="item"
                                :class="{ 'act': typeAct == index }" @click="typeAct = index">
                                <div v-if="item.turntableSwitch == 'silver'">{{ $t('turntable.Silver') }}</div>
                                <div v-if="item.turntableSwitch == 'gold'">{{ $t('turntable.Gold') }}</div>
                                <div v-if="item.turntableSwitch == 'diamond'">{{ $t('turntable.Diamond') }}</div>
                                <div>
                                    <span>{{ item.luckyNum }}</span>
                                    <span>{{ $t('turntable.totalTips4') }}</span>
                                </div>
                            </div>
                        </div>
                        <div v-if="turntableList.length">
                            <div v-for="(item, index) in turntableList" :key="index">
                                <div v-if="typeAct == index && item.luckyWheelModelDetailOddsVoList.length"
                                    class="turntable"
                                    :class="{ 'turntable1': item.turntableSwitch == 'silver', 'turntable2': item.turntableSwitch == 'gold', 'turntable3': item.turntableSwitch == 'diamond' }">
                                    <img v-if="item.turntableSwitch == 'silver'" src="@/assets/turntable/turntable1.png"
                                        alt="" class="turntable_bg">
                                    <img v-if="item.turntableSwitch == 'gold'" src="@/assets/turntable/turntable2.png"
                                        alt="" class="turntable_bg">
                                    <img v-if="item.turntableSwitch == 'diamond'"
                                        src="@/assets/turntable/turntable3.png" alt="" class="turntable_bg">
                                    <div class="outSideBg"></div>
                                    <div class="turntable_main" :style="getMainStyle">
                                        <div class="item"
                                            :class="{ 'item1': item.turntableSwitch == 'silver', 'item2': item.turntableSwitch == 'gold', 'item3': item.turntableSwitch == 'diamond' }"
                                            v-for="(citem, cindex) in item.luckyWheelModelDetailOddsVoList"
                                            :key="cindex" :style="[getRotateAngle(cindex)]">
                                            <div v-if="citem.amount" class="item-content"
                                                :style="[getCorrectAngle(cindex)]">
                                                <div class="item-name" :class="{ act: amt == citem.amount }">
                                                    {{ citem.amount }}
                                                </div>
                                                <!-- <img :src="citem.img || require('@/assets/turntable/money_icon.png')"
                                                    alt="" class="item-img"> -->
                                                <!-- <img src="@/assets/turntable/star.png" alt="" class="light"> -->
                                            </div>
                                            <div v-else class="item-empty" :style="[getCorrectAngle(cindex)]">
                                                <img src="@/assets/turntable/empty-money.png" alt="" class="item-img">
                                            </div>
                                            <!-- <div class="star" :style="[getCorrectAngle(cindex)]">
                                                <img src="@/assets/turntable/star.png" alt="" class="item-img">
                                            </div> -->
                                        </div>
                                    </div>
                                    <div class="turntable_main__shadow"></div>
                                    <div class="btn" @click="onTurn(item.turntableSwitch)">
                                        <img :src="require(`@/assets/turntable/turntable-btn${item.turntableSwitch == 'silver' ? 1 : (item.turntableSwitch == 'gold' ? 2 : 3)}.png`)"
                                            class="turntable_btn">
                                        <div>{{ $t("turntable.DrawNow") }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tabs">
                        <div class="tabs-header">
                            <div class="tabs-header-item" :class="{ act: tabAct === 0 }" @click="onChangeTab(0)">
                                {{ $t('turntable.tab1') }}
                            </div>
                            <div class="tabs-header-item" :class="{ act: tabAct === 1 }" @click="onChangeTab(1)">
                                {{ $t('turntable.tab2') }}
                            </div>
                        </div>
                        <div class="tabs-content">
                            <div class="tabs-content-inset">
                                <div class="tabs-content-item" v-if="tabAct == 0">
                                    <div :style="dataScrollStyle">
                                        <div class="tr" v-for="(item, index) in winningList" :key="index">
                                            <div class="td"> {{ item.phone }} </div>
                                            <div class="td">{{ $t('turntable.JustRemoved') }}</div>
                                            <div class="td money">{{ CurrencyType }}+{{ item.money }}</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="tabs-content-item records" v-if="tabAct == 1">
                                    <div v-if="recordList.length">
                                        <div class="tr" v-for="(item, index) in recordList" :key="index">
                                            <div class="td">{{ item.createTime }}</div>
                                            <div class="td">{{ $t('turntable.JustRemoved') }}</div>
                                            <div class="td money">{{ CurrencyType }}+{{ item.amount }}</div>
                                        </div>
                                        <div v-if="item.isLoading" class="last-tips">
                                            <a-spin>
                                                <a-icon slot="indicator" type="loading" spin />
                                            </a-spin>
                                            {{ $t("lang.Loading") }}
                                        </div>
                                        <div class="last-tips" v-else-if="recordHasMore"
                                            @click="getTurntableDrawRecords">
                                            {{ $t("lang.LoadMore") }}
                                        </div>
                                        <div class="last-tips">{{ $t("lang.AllLoaded") }}</div>
                                    </div>
                                    <div class="empty" v-else>
                                        <img src="@/assets/common/empty.png" alt="" class="empty_img">
                                        <div>{{ $t("lang.NoData") }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="rule_box">
                        <div class="title">
                            {{ $t("signin.Rules") }}
                        </div>
                        <div class="rule" v-html="rulesDesc"></div>
                    </div>
                </div>
            </div>
        </van-popup>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import { Dialog } from 'vant'
import {
    getEventsHomeShow
} from '@/api/home'
import { getTurntableInfo, getTurntableDrawRecords, onTurntableDraw } from '@/api/turntable'
export default {
    name: 'turntablePop',
    components: {},
    data() {
        return {
            firstLoaded: true,
            memberLuckyAmt: 0, // 用户当前拥有多少幸运值
            bettingGainLucky: 1, // 投注1获得多少幸运点
            luckyWheelReward: '', // gameBetting有效投注(默认)  everydayFirstrecharge每日首充
            turntableList: [],
            typeAct: 0,
            prizeList: [], // 奖品列表，
            isTurnIng: false, // 是否转动
            startRotateDegree: 0, // 开始转动的角度
            rotateAngle: 0, // 设置指针默认指向的位置,现在是默认指向2个扇形之间的边线上
            rotateTransition: '',
            amt: 0, // 中奖金额
            tabAct: 0,
            // 中奖名单
            winningList: [],
            getWinningListInterval: null,
            dataScrollStyle: '',
            dataScrollInterval: null,
            recordPageNo: 0,
            recordHasMore: true,
            recordLoading: false,
            recordList: [],
            rulesDesc: '' // 活动说明
        }
    },
    computed: {
        isLogin() {
            return !!this.$store.getters.token
        },
        ...mapState({
            device: state => state.app.device
        }),
        classObj() {
            return {
                mobile: this.device === 'mobile'
            }
        },
        eventId: {
            get() {
                return this.$store.state.user.turntableEventId
            },
            set() { }
        },
        show: {
            get() {
                return this.$store.getters.isShowTurntablePopup
            },
            set(val) {
                return val
            }
        },
        getRotateAngle() {
            return index => {
                const list = this.turntableList.length ? this.turntableList[this.typeAct].luckyWheelModelDetailOddsVoList : []
                return {
                    transform: `rotate(${(360 / list.length) * index}deg) skewY(${360 / list.length - 90}deg)`
                }
            }
        },
        getCorrectAngle() {
            return index => {
                const list = this.turntableList.length ? this.turntableList[this.typeAct].luckyWheelModelDetailOddsVoList : []
                return {
                    transform: `skewY(${90 - 360 / list.length}deg) skewX(0deg) rotate(${180 / list.length}deg)`
                }
            }
        },
        getMainStyle() {
            return `
				transform: ${this.rotateAngle};
				transition: ${this.rotateTransition};
			`
        }
    },
    created() {
    },
    destroyed() {
        clearInterval(this.dataScrollInterval)
        clearInterval(this.getWinningListInterval)
    },
    methods: {
        open() {
            if (this.firstLoaded) {
                this.init()
            }
        },
        close() {
            this.$store.dispatch('user/setIsShowTurntablePopup', { id: '', show: false })
        },
        init() {
            this.getInfo()
        },
        getInfo() {
            const that = this
            if (this.eventId) {
                that.getTurntableInfo(this.eventId).then((list) => {
                    that.getWinningList(list)
                    that.getWinningListInterval = setInterval(() => {
                        that.getWinningList(list)
                    }, 60 * 1000 * 10)
                })
                this.onDataScroll()
            } else {
                getEventsHomeShow().then((res) => {
                    if (res.code) return
                    const D = res.result.eventManageList
                    const item = D.filter(item => item.eventType === 'luckyWheel')
                    const that = this
                    that.eventId = item[0].id
                    that.getTurntableInfo(that.eventId).then((list) => {
                        that.getWinningList(list)
                        that.getWinningListInterval = setInterval(() => {
                            that.getWinningList(list)
                        }, 60 * 1000 * 10)
                    })
                    this.onDataScroll()
                })
            }
        },
        getTurntableInfo(id) {
            return new Promise((resolve, reject) => {
                getTurntableInfo({ id }).then((res) => {
                    this.LoadingNum++
                    if (res.code) return
                    const D = res.result
                    this.memberLuckyAmt = D.value1.luckyAmt
                    this.bettingGainLucky = D.value0.bettingGainLucky
                    this.rulesDesc = D.value0.eventContent
                    this.turntableList = D.value0.luckyWheelModelDetailVoList || []
                    this.luckyWheelReward = D.value0.luckyWheelReward
                    this.firstLoaded = false
                    resolve(this.turntableList[this.turntableList.length - 1]?.luckyWheelModelDetailOddsVoList)
                }).catch(err => {
                    reject(err)
                })
            })
        },
        onChangeTab(act) {
            if (this.tabAct === act) return
            this.tabAct = act
            if (act === 0) {
                this.onDataScroll()
            }
            if (act === 1) {
                this.getTurntableDrawRecords()
            }
        },
        // 获取抽奖记录
        getTurntableDrawRecords() {
            this.recordPageNo++
            if (this.recordLoading) return
            this.recordLoading = true
            getTurntableDrawRecords({
                pageNo: this.recordPageNo,
                pageSize: 20
            }).then((res) => {
                if (res.code) return
                const D = res.result
                this.recordList = this.recordList.concat(D.records)
                this.recordHasMore = D.total > this.recordList.length
            })
        },
        getWinningList(arr) {
            let mArr = [100, 100]
            if (arr && arr.length) {
                mArr = arr.map(x => x.amount)
            }
            const list = []
            for (let i = 0; i < 30; i++) {
                const start = this.randNum(1000, 9999)
                const end = this.randNum(100, 999)
                const mArrIdx = this.randNum(0, mArr.length - 1)
                const money = mArr[mArrIdx]
                const obj = {
                    phone: start + '****' + end,
                    money
                }
                list.push(obj)
            }
            this.winningList = list
        },
        randNum(m, n) {
            return Math.ceil(Math.random() * (n - m + 1) + m - 1)
        },
        onDataScroll() {
            if (this.dataScrollInterval) clearInterval(this.dataScrollInterval)
            this.dataScrollStyle = ''
            const dataScrollTimeout1 = setTimeout(() => {
                this.dataScrollStyle = 'transition: all 8s linear 0s;transform: translateY(-50%);'
                this.dataScrollInterval = setInterval(() => {
                    this.dataScrollStyle = ''
                    const dataScrollTimeout2 = setTimeout(() => {
                        this.dataScrollStyle =
                            'transition: all 8s linear 0s;transform: translateY(-50%);'
                        clearTimeout(dataScrollTimeout2)
                    }, 20)
                }, 8000)
                clearTimeout(dataScrollTimeout1)
            }, 20)
        },
        onTurn(id) {
            if (this.isTurnIng) return
            this.isTurnIng = true
            const params = {
                eventId: this.eventId,
                turntableSwitch: id
            }
            onTurntableDraw(params).then((res) => {
                const that = this
                if (res.code === 0) {
                    const D = res.result
                    that.amt = D
                    let idx = 0
                    const list = that.turntableList[that.typeAct].luckyWheelModelDetailOddsVoList
                    list.forEach((x, i) => {
                        if (x.amount === D) {
                            idx = i
                        }
                    })
                    that.run(idx).then(() => {
                        Dialog.alert({
                            title: that.$t('turntable.drawTitle1'),
                            message: `${that.$t('turntable.drawContent1')} ${that.CurrencyType} ${D}`,
                            confirmButtonText: that.$t('lang.confirm'),
                            confirmButtonColor: '#000000',
                            closeOnClickOverlay: true
                        }).then(() => {
                            that.getTurntableInfo()
                            if (that.tabAct === 1) {
                                that.recordPageNo = 0
                                that.recordHasMore = true
                                that.recordLoading = false
                                that.recordList = []
                                that.getTurntableDrawRecords()
                            }
                        })
                    })
                } else if (res.code === -1) {
                    this.isTurnIng = false
                }
            })
        },
        run(index) {
            return new Promise((resolve, reject) => {
                const duration = 5
                this.rotateTransition = `transform ${duration}s cubic-bezier(0.250, 0.460, 0.455, 0.995)`
                const list = this.turntableList.length ? this.turntableList[this.typeAct].luckyWheelModelDetailOddsVoList : []
                const length = list.length
                const rotateAngle = this.startRotateDegree + 10 * 360 + 360 - (180 / length + (360 / length) *
                    index) - (this.startRotateDegree % 360)
                this.startRotateDegree = rotateAngle
                this.rotateAngle = `rotate(${rotateAngle}deg)`
                setTimeout(() => {
                    resolve()
                    this.isTurnIng = false
                }, duration * 1000 + 1000)
            })
        }
    }
}
</script>

<style scoped lang="scss">
@import '@/common/styles/viriables.scss';

.popup {
    &.mobile {

        .van-popup {
            &.van-popup--center {
                width: 82.6% !important;
                height: max-content !important;
                max-width: 9rem !important;
                min-height: 8.6rem !important;
                max-height: 64.5% !important;
            }

            .content {
                width: 100% !important;
                padding-bottom: .4rem;

                .header {
                    height: .94rem;
                    padding: 0 .28rem;

                    &>div:first-child {
                        font-size: .3rem;
                    }

                    .close {
                        font-size: .3rem;
                    }
                }

                .box {
                    padding: .4rem .4rem 0;

                    .total {
                        padding: .2rem;
                        margin: 0 0 .2rem;
                        border-radius: .24rem;

                        .total1 {
                            font-size: .2rem;
                            padding-bottom: .2rem;
                        }

                        .total2 {
                            font-size: .2rem;
                            padding-top: .2rem;
                        }
                    }

                    .turntable_content {
                        .type {
                            height: .86rem;
                            column-gap: .1rem;
                            margin-bottom: .2rem;

                            .item {
                                border-radius: .08rem;

                                >div {
                                    &:first-child {
                                        font-size: .2rem;
                                    }

                                    &:last-child {
                                        font-size: .14rem;
                                    }
                                }
                            }
                        }

                        .turntable {
                            position: relative;
                            width: 5.5rem;
                            height: 5.5rem;
                            margin: .2rem auto;
                            z-index: 2;

                            .turntable_main {
                                width: 4rem;
                                height: 4rem;
                                bottom: calc(50% - 2rem);
                                left: calc(50% - 2rem);

                                .item {

                                    .item-content {
                                        padding-top: .3rem;

                                        .item-name {
                                            padding-top: .3rem;
                                            padding-bottom: .3rem;
                                            font-size: .22rem;
                                        }

                                        .item-img {
                                            width: .5rem;
                                            height: .5rem;
                                        }

                                        .light {
                                            width: .34rem;
                                            height: .34rem;
                                            transform: translateY(0.2rem);
                                        }
                                    }

                                    .item-empty {
                                        padding-top: .5rem;

                                        .item-img {
                                            width: .5rem;
                                            height: .5rem;
                                        }
                                    }

                                    .star {
                                        position: absolute;
                                        padding-top: 0;
                                        box-sizing: border-box;
                                        width: 100%;
                                        height: 100%;
                                        left: -65%;
                                        bottom: -27%;
                                        display: flex;
                                        flex-direction: column;
                                        align-items: center;

                                        .item-img {
                                            width: .4rem;
                                            height: .4rem;
                                            vertical-align: middle;
                                        }
                                    }

                                }
                            }

                            .btn {
                                position: absolute;
                                position: absolute;
                                width: 1.8rem;
                                height: 1.8rem;
                                bottom: calc(50% - 0.9rem);

                                >div {
                                    position: absolute;
                                    left: 50%;
                                    top: 50%;
                                    transform: translate(-50%, -50%);
                                    color: #FDDFA2;
                                    font-size: .24rem;
                                    line-height: .24rem;
                                }
                            }

                            .turntable_btn {
                                width: 100%;
                                height: 100%;
                            }
                        }
                    }

                    .tabs {
                        margin: 0 0 .2rem;
                        border-radius: .24rem;

                        .tabs-header {
                            font-size: .24rem;
                            padding: 0 .28rem;

                            .tabs-header-item {
                                padding: 0 .2rem;

                                &.act {
                                    height: .7rem;
                                    line-height: .68rem;
                                }
                            }
                        }

                        .tabs-content {
                            border-radius: .24rem;
                            padding: .2rem .28rem;

                            .tabs-content-item {
                                height: 6.22rem;

                                &.records {

                                    .last-tips {
                                        line-height: .62rem;
                                        font-size: .28rem;
                                    }

                                    .empty {
                                        font-size: .28rem;

                                        .empty_img {
                                            width: 3.4rem;
                                            object-fit: contain;
                                        }
                                    }
                                }

                                .tr {
                                    height: .62rem;
                                    font-size: .22rem;
                                }
                            }
                        }
                    }

                    .rule_box {
                        border: .02rem solid $border-color;
                        font-size: .2rem;
                        padding: .2rem;

                        .title {
                            font-size: .24rem;
                            margin-bottom: .2rem;
                        }
                    }
                }
            }
        }
    }

    .van-popup {
        background: transparent;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;

        .content {
            background: $bg-popup-color;
            width: 500px;
            height: 700px;
            border-radius: .24rem;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            padding-bottom: 30px;

            .header {
                display: flex;
                height: 76px;
                padding: 0 30px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                background: $bg-popup-navbar-color;

                &>div:first-child {
                    font-size: 20px;
                    font-weight: bold;
                    color: #fff;
                }

                .close {
                    color: $font-iconfont-color;
                    font-size: 16px;
                    font-weight: bold;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    transition: all 0.3s;

                    &:hover {
                        color: #ffffff;
                        rotate: -180deg;
                    }
                }
            }

            .box {
                flex: 1;
                display: flex;
                flex-direction: column;
                padding: 20px 30px 0;
                overflow-y: scroll;

                .total {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    padding: 12px;
                    margin: 0 0 10px;
                    background: $bg-menu-color;
                    border-radius: .24rem;

                    .total1 {
                        text-align: center;
                        color: $font-sub-color;
                        font-size: 12px;
                        padding-bottom: 14px;

                        .num {
                            color: #f3cc70;
                            font-weight: bold;
                        }
                    }

                    .total2 {
                        color: $font-sub-color;
                        font-size: 12px;
                        text-align: center;
                        position: relative;
                        z-index: 1;
                        border-top: 1px solid $border-color;
                        padding-top: 14px;

                        .num {
                            color: #FFC480;
                            font-weight: bold;
                        }
                    }
                }

                .turntable_content {
                    .type {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 64px;
                        column-gap: 7px;
                        margin-bottom: 42px;

                        .item {
                            height: 100%;
                            flex: 1;
                            color: $font-sub-color;
                            border-radius: 6px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            flex-direction: column;
                            background: linear-gradient(163deg, #16191E, #3B404C);
                            // border: .02rem solid #655A56;
                            cursor: pointer;

                            &.act {
                                border: none;
                                background: $bg-btn-color;

                                >div {
                                    &:first-child {
                                        color: $font-btn-color;
                                    }

                                    &:last-child {
                                        color: $font-btn-color;
                                    }
                                }
                            }

                            >div {
                                &:first-child {
                                    font-size: 16px;
                                    font-weight: bold;
                                    color: #ffffff;
                                }

                                &:last-child {
                                    font-size: 13px;
                                    color: #FFC480;
                                }
                            }
                        }
                    }

                    .turntable {
                        position: relative;
                        width: 450px;
                        height: 450px;
                        margin: 10px auto;
                        z-index: 2;

                        .outSideBg {
                            position: absolute;
                            top: 6%;
                            bottom: 6%;
                            left: 6%;
                            right: 6%;
                            border-radius: 50%;
                        }

                        &.turntable1 {
                            .outSideBg {
                                background: linear-gradient(0deg, #1144D3 0%, #0F48E5 98%);
                            }

                            .turntable_main {
                                // &:after {
                                //     border: 2px solid #6586ad;
                                // }

                                .item {
                                    border-image: linear-gradient(0deg, #B4CCD9, #CADDE7) 10 10;

                                    &:nth-child(2n) {
                                        background: #2458eb;

                                        .item-content {
                                            .item-name {
                                                color: #ffffff;
                                            }
                                        }
                                    }

                                    &:nth-child(2n+1) {
                                        background: #ffffff;

                                        .item-content {
                                            .item-name {
                                                color: #2458EB;
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        &.turntable2 {
                            .outSideBg {
                                background: linear-gradient(0deg, #D99B13 0%, #F5D285 98%);
                            }

                            .turntable_main {
                                // &:after {
                                //     border: 2px solid #fac348;
                                // }

                                .item {
                                    border-image: linear-gradient(0deg, #FFE577, #FDB649) 10 10;

                                    &:nth-child(2n) {
                                        background: #FFAF10;

                                        .item-content {
                                            .item-name {
                                                color: #ffffff;
                                            }
                                        }
                                    }

                                    &:nth-child(2n+1) {
                                        background: #ffffff;

                                        .item-content {
                                            .item-name {
                                                color: #eeac1c;
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        &.turntable3 {
                            .outSideBg {
                                background: linear-gradient(0deg, #9C1BDA 0%, #C54AFF 98%);
                            }

                            .turntable_main {
                                // &:after {
                                //     border: 2px solid #bd73de;
                                // }

                                .item {
                                    border-image: linear-gradient(0deg, #D697F4, #F2D6FF) 10 10;

                                    &:nth-child(2n) {
                                        background: #9D1ADB;

                                        .item-content {
                                            .item-name {
                                                color: #ffffff;
                                            }
                                        }
                                    }

                                    &:nth-child(2n+1) {
                                        background: #ffffff;

                                        .item-content {
                                            .item-name {
                                                color: #9D1ADB;
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        .turntable_bg {
                            width: 100%;
                            height: 100%;
                            vertical-align: middle;
                            position: relative;
                            z-index: 8;
                        }

                        .turntable_main {
                            position: absolute;
                            // background: #9DB3BF;
                            // border: 4px solid #9fbfcf;
                            z-index: 1;
                            width: 327px;
                            height: 327px;
                            bottom: calc(50% - 163px);
                            left: calc(50% - 163px);
                            overflow: hidden;
                            border-radius: 50%;
                            box-sizing: border-box;
                            transition: transform 8s cubic-bezier(0.250, 0.460, 0.455, 0.995);
                            z-index: 10;

                            // &:after {
                            //     content: '';
                            //     width: 1.2rem;
                            //     height: .6rem;
                            //     background: #ffffff;
                            //     // box-shadow: 0 .04rem .08rem 0 rgba(0, 0, 0, 0.65) inset;
                            //     position: absolute;
                            //     top: -10%;
                            //     left: 50%;
                            //     transform: translateX(-50%) skewY(45deg) rotateZ(112deg);
                            // }

                            &__shadow {
                                position: absolute;
                                overflow: hidden;
                                border-radius: 50%;
                                top: 13%;
                                left: 13%;
                                bottom: 13%;
                                right: 13%;
                                // box-shadow: 0 0 24px 0 #a1a3a5;
                                box-shadow: 0 0 12px 2px #7a7b7d inset;
                                z-index: 10;
                            }

                            .item {
                                // overflow: hidden;
                                position: absolute;
                                top: -50%;
                                left: 50%;
                                width: 100%;
                                height: 100%;
                                transform-origin: 0 100%;
                                border-left: 5px solid;
                                box-shadow: 0px 0 4px 0px rgba(0, 0, 0, 1) inset;

                                .item-content {
                                    position: absolute;
                                    padding-top: .4rem;
                                    box-sizing: border-box;
                                    width: 100%;
                                    height: 100%;
                                    left: -50%;
                                    bottom: -50%;
                                    display: flex;
                                    flex-direction: column;
                                    align-items: center;

                                    .light {
                                        width: 26px;
                                        height: 26px;
                                        transform: translateY(36px);
                                    }

                                    .item-name {
                                        padding-top: 10px;
                                        padding-bottom: 10px;
                                        font-size: .32rem;
                                        color: #333;
                                        rotate: 90deg;

                                        &.act {
                                            animation: scale 1s linear infinite;
                                        }
                                    }

                                    @keyframes scale {
                                        0% {
                                            scale: 1;
                                        }

                                        50% {
                                            scale: 1.3;
                                        }

                                        100% {
                                            scale: 1;
                                        }
                                    }

                                    .item-img {
                                        width: 32px;
                                        height: 32px;
                                        // vertical-align: middle;
                                    }
                                }

                                .star {
                                    position: absolute;
                                    padding-top: 0;
                                    box-sizing: border-box;
                                    width: 100%;
                                    height: 100%;
                                    left: -65%;
                                    bottom: -26%;
                                    display: flex;
                                    flex-direction: column;
                                    align-items: center;

                                    .item-img {
                                        width: 32px;
                                        height: 32px;
                                        vertical-align: middle;
                                    }
                                }

                                .item-empty {
                                    position: absolute;
                                    padding-top: 39px;
                                    box-sizing: border-box;
                                    width: 100%;
                                    height: 100%;
                                    left: -50%;
                                    bottom: -50%;
                                    display: flex;
                                    flex-direction: column;
                                    align-items: center;

                                    .item-img {
                                        width: 32px;
                                        height: 32px;
                                        vertical-align: middle;
                                    }
                                }
                            }

                        }

                        .btn {
                            position: absolute;
                            position: absolute;
                            width: 120px;
                            height: 120px;
                            bottom: calc(50% - 60px);
                            left: 50%;
                            transform: translateX(-50%);
                            z-index: 11;
                            cursor: pointer;

                            >div {
                                position: absolute;
                                left: 50%;
                                top: 50%;
                                transform: translate(-50%, -50%);
                                color: #FDDFA2;
                                font-size: .28rem;
                                line-height: .28rem;
                            }
                        }

                        .turntable_btn {
                            width: 100%;
                            height: 100%;
                        }
                    }
                }

                .tabs {
                    margin: 15px 0;
                    background: $bg-menu-color;
                    border-radius: .24rem;

                    .tabs-header {
                        box-sizing: border-box;
                        font-size: 14px;
                        color: rgba(255, 255, 255, 0.65);
                        margin: 0 auto;
                        position: relative;
                        z-index: 2;
                        display: flex;
                        align-items: center;
                        padding: 0 14px;

                        .tabs-header-item {
                            cursor: pointer;
                            padding: 0 10px;
                            border-radius: .24rem;
                            text-align: center;
                            flex: 1;

                            &.act {
                                height: 45px;
                                line-height: 44px;
                                color: #FFFFFF;
                                font-weight: bold;
                                position: relative;
                                color: #f3cc70;

                                &:after {
                                    content: '';
                                    width: 0;
                                    height: 0;
                                    border-left: .1rem solid transparent;
                                    border-right: .1rem solid transparent;
                                    border-bottom: .1rem solid #f3cc70;
                                    position: absolute;
                                    bottom: .12rem;
                                    left: 50%;
                                    transform: translate(-50%, 100%);
                                }
                            }
                        }
                    }

                    .tabs-content {
                        border-radius: .24rem;
                        padding: 20px 14px;
                        padding-top: 0;

                        .tabs-content-inset {
                            border-radius: .24rem;
                        }

                        .tabs-content-item {
                            height: 311px;
                            overflow: hidden;
                            border-radius: .24rem;

                            &.records {
                                overflow: auto;
                                position: relative;

                                .last-tips {
                                    line-height: 31px;
                                    width: 100%;
                                    text-align: center;
                                    color: $font-sub-color;
                                    font-size: 14px;
                                }

                                .empty {
                                    position: absolute;
                                    left: 50%;
                                    top: 50%;
                                    transform: translate(-50%, -50%);
                                    color: $font-sub-color;
                                    font-size: 16px;
                                    text-align: center;

                                    .empty_img {
                                        width: 170px;
                                        object-fit: contain;
                                    }
                                }
                            }

                            .tr {
                                height: 31px;
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                                color: #ffffff;
                                font-size: 11px;

                                &:nth-child(2n+1) {
                                    background: $bg-box-color;
                                }

                                &:nth-child(2n) {
                                    // background: rgba(42, 44, 52, 0.9);
                                }

                                .td {
                                    text-align: center;
                                    width: 36%;
                                    white-space: nowrap;

                                    &:last-child {
                                        width: 28%;
                                    }

                                    &.money {
                                        color: #FFCE2D;
                                    }
                                }
                            }
                        }
                    }
                }

                .rule_box {
                    border: 2px solid $border-color;
                    color: $font-sub-color;
                    font-size: 12px;
                    text-align: left;
                    border-radius: .24rem;
                    padding: 10px;

                    .title {
                        font-size: 16px;
                        margin-bottom: 10px;
                        font-weight: bold;
                        background: linear-gradient(0deg, #FF940A 0%, #FAC988 100%);
                        -webkit-background-clip: text;
                        background-clip: text;
                        -webkit-text-fill-color: transparent;
                    }

                    .rule {
                        color: #ffffff;
                    }
                }
            }
        }
    }
}
</style>
