<template>
    <div class="popup" :class="classObj">
        <van-popup :value="show" @click-overlay="close" @open="open">
            <div class="content">
                <statusBar></statusBar>
                <div class="header">
                    <div>{{ $t("deposit.ChoosePaymentSystem") }}</div>
                    <div class="close">
                        <i class="iconfont icon-s-cuowu-guanbi" @click="close"></i>
                    </div>
                </div>
                <div class="DepositMethods-list">
                    <div class="DepositMethods-item" :class="{ 'act': item.id === payListId }"
                        v-for="(item, index) in totalPayList" :key="index" @click="handlePayListItemClick(item)">
                        <img :src="item.imgUrl" />
                    </div>
                </div>
                <div class="Deposit-main">
                    <div class="bouns-box" :class="{ act: showBonus }">
                        <div class="bouns-box-header">
                            <div>
                                <img v-if="showBonus" src="@/assets/deposit/icon_bonusAct.png" />
                                <img v-else src="@/assets/deposit/icon_bonus.png" />
                                <div>{{ $t("deposit.isNeedBonus") }}</div>
                            </div>
                            <van-switch v-model="showBonus" size="20px" active-color="rgb(255, 120, 45)"
                                inactive-color="#777" />
                        </div>
                        <div v-if="showBonus"
                            style="color: #B9B0AE;line-height: .48rem; text-align: left;padding-left: 1.12rem;">
                            <div v-if="((depositType === 'TransferDeposit' && usdtGratuityConditions == 'MAXIMUM') || (depositType === 'OnlineDeposit' && gratuityConditions == 'MAXIMUM')) && (userInfo && userInfo.firstRechargeState == 'No')"
                                class="txt">
                                <div
                                    v-if="rewardPercentage1 && rewardPercentage1 > rewardPercentage2 && rewardPercentage1 > rewardPercentage3">
                                    {{ rewardPercentageTxt1 }}</div>
                                <div
                                    v-if="rewardPercentage2 && rewardPercentage2 > rewardPercentage3 && rewardPercentage2 >= rewardPercentage1">
                                    {{ rewardPercentageTxt2 }}</div>
                                <div
                                    v-if="rewardPercentage3 && rewardPercentage3 >= rewardPercentage2 && rewardPercentage3 >= rewardPercentage1">
                                    {{ rewardPercentageTxt3 }}</div>
                            </div>
                            <div v-else-if="((depositType === 'TransferDeposit' && usdtGratuityConditions == 'MAXIMUM') || (depositType === 'OnlineDeposit' && gratuityConditions == 'MAXIMUM')) && (userInfo && userInfo.everyFirstRechargeState == 'No')"
                                class="txt">
                                <div v-if="rewardPercentage1 && rewardPercentage1 >= rewardPercentage2">
                                    {{ rewardPercentageTxt1 }}</div>
                                <div v-if="rewardPercentage2 && rewardPercentage2 > rewardPercentage1">
                                    {{ rewardPercentageTxt2 }}</div>
                            </div>
                            <div v-else-if="((depositType === 'TransferDeposit' && usdtGratuityConditions == 'MAXIMUM') || (depositType === 'OnlineDeposit' && gratuityConditions == 'MAXIMUM'))"
                                class="txt">
                                <div v-if="rewardPercentage1">{{ rewardPercentageTxt1 }}</div>
                            </div>
                            <div v-else class="txt">
                                <div v-if="rewardPercentage1">{{ rewardPercentageTxt1 }}</div>
                                <div v-if="rewardPercentage2">{{ rewardPercentageTxt2 }}</div>
                                <div v-if="rewardPercentage3">{{ rewardPercentageTxt3 }}</div>
                            </div>
                        </div>
                    </div>
                    <template v-if="depositType === 'TransferDeposit'">
                        <div class="DepositContent" :class="{ 'hide': isShowPayList }">
                            <div class="amount_input">
                                <div class="Recharge-Limt">{{ minRechargeAmt }} - {{ maxRechargeAmt }} U </div>
                                <a-input v-model="usdtRechargeValue" type="number"
                                    :placeholder="$t('deposit.Amount') + '(U)'" @change="handleRechargeChange">
                                    <div v-if="rewardPercentage && showBonus" slot="suffix" class="extra">
                                        {{ $t("deposit.Extra") }} + U {{ rewardPercentage }}
                                    </div>
                                    <div v-else slot="suffix" class="noBonus">
                                        {{ $t("deposit.noBonus") }}
                                    </div>
                                </a-input>
                            </div>
                            <div class="exchangeRate">
                                <div class="exchangeRate_left">
                                    <div>{{ $t('deposit.ExchangeRate') }}</div>
                                    <div>
                                        <span>U1 : {{ CurrencyType }}{{ changeRate }}</span>
                                        <span class="iconfont icon-shuaxin" @click="getRate"></span>
                                    </div>
                                </div>
                                <div class="exchangeRate_right">
                                    <div>
                                        <div>{{ $t('deposit.Deposit') }}</div>
                                        <div>≈{{ rateMoney }}{{ currency }}</div>
                                    </div>
                                    <div v-if="rewardPercentage && showBonus">
                                        <div>{{ $t('deposit.Bonus') }}</div>
                                        <div>≈{{ rateBonus }}{{ currency }}</div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="usdtList && usdtList.length" class="form_gap" style="margin-top: .22rem;">
                                <div class="form_gap_item" :class="{ 'act': item.rechargeAmt == usdtRechargeValue }"
                                    v-for="(item, index) in usdtList" :key="index" @click="handleUsdtListItemClick(item)">
                                    <div class="num">U {{ item.rechargeAmt }}</div>
                                    <div v-if="showBonus">
                                        <div class="extra"
                                            v-if="usdtGratuityConditions == 'MAXIMUM' && (item.everyHandselRate || (userInfo && userInfo.everyFirstRechargeState == 'No' && item.handselRate) || (userInfo && userInfo.firstRechargeState == 'No' && item.firstHandselRate))">
                                            <span v-if="userInfo && userInfo.firstRechargeState == 'No'">
                                                +{{ item.firstHandselRate > item.handselRate ? (item.firstHandselRate >
                                                    item.everyHandselRate ? (item.firstHandselRate || 0).toFixed(2) :
                                                    (item.everyHandselRate || 0).toFixed(2)) : (item.handselRate >
                                                        item.everyHandselRate ? (item.handselRate || 0).toFixed(2) :
                                                        (item.everyHandselRate || 0).toFixed(2)) }}
                                            </span>
                                            <span v-else-if="userInfo && userInfo.everyFirstRechargeState == 'No'">
                                                +{{ item.handselRate > item.everyHandselRate ? (item.handselRate ||
                                                    0).toFixed(2) : (item.everyHandselRate || 0).toFixed(2) }}
                                            </span>
                                            <span v-else>{{ (item.everyHandselRate || 0).toFixed(2) }}</span>
                                            <span>%</span>
                                        </div>
                                        <div class="extra"
                                            v-else-if="item.everyHandselRate || (userInfo && userInfo.everyFirstRechargeState == 'No' && item.handselRate) || (userInfo && userInfo.firstRechargeState == 'No' && item.firstHandselRate)">
                                            <div
                                                v-if="userInfo && userInfo.firstRechargeState == 'No' && item.firstHandselRate">
                                                +{{ item.handselRate + item.firstHandselRate + item.everyHandselRate }}%
                                            </div>
                                            <div
                                                v-else-if="userInfo && userInfo.everyFirstRechargeState == 'No' && item.handselRate">
                                                +{{ item.handselRate + item.everyHandselRate }}%
                                            </div>
                                            <div v-else>+{{ (item.everyHandselRate || 0).toFixed(2) }}%</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <a-button class="submit-btn" @click="handleUsdtRecharge">{{ $t("deposit.Deposit") }}</a-button>
                            <a-button class="submit-btn toTransaction" @click="toTransaction">{{
                                $t("Transaction.Transaction") }}</a-button>
                            <div v-if="rulesDesc" class="rulesDesc">
                                <div v-html="rulesDesc"></div>
                            </div>
                        </div>
                    </template>
                    <template v-if="depositType === 'OnlineDeposit'">
                        <div class="DepositContent" :class="{ 'hide': isShowPayList }">
                            <div v-if="BelongNation !== 'USA'" class="amount_input">
                                <div class="Recharge-Limt">
                                    {{ minRechargeAmt }} - {{ maxRechargeAmt }} {{ currency }}
                                </div>
                                <a-input v-model="rechargeValue" type="number"
                                    :placeholder="$t('deposit.Amount') + '(' + currency + ')'"
                                    @change="handleRechargeChange">
                                    <div v-if="rewardPercentage && showBonus" slot="suffix" class="extra">
                                        {{ $t("deposit.Extra") }} + {{ CurrencyType }} {{ rewardPercentage }}
                                    </div>
                                    <div v-else slot="suffix" class="noBonus">
                                        {{ $t("deposit.noBonus") }}
                                    </div>
                                </a-input>
                            </div>
                            <div class="form_gap">
                                <div class="form_gap_item" :class="{ 'act': item.rechargeAmt == rechargeValue }"
                                    v-for="(item, index) in RechargeList" :key="index" @click="handleListItemClick(item)">
                                    <div class="num">{{ CurrencyType }} {{ item.rechargeAmt }}</div>
                                    <div v-if="showBonus">
                                        <div class="extra"
                                            v-if="gratuityConditions == 'MAXIMUM' && (item.everyHandselRate || (userInfo && userInfo.everyFirstRechargeState == 'No' && item.handselRate) || (userInfo && userInfo.firstRechargeState == 'No' && item.firstHandselRate))">
                                            <span v-if="userInfo && userInfo.firstRechargeState == 'No'">
                                                +{{ item.firstHandselRate > item.handselRate ? (item.firstHandselRate >
                                                    item.everyHandselRate ? (item.firstHandselRate || 0).toFixed(2) :
                                                    (item.everyHandselRate || 0).toFixed(2)) : (item.handselRate >
                                                        item.everyHandselRate ? (item.handselRate || 0).toFixed(2) :
                                                        (item.everyHandselRate || 0).toFixed(2)) }}
                                            </span>
                                            <span v-else-if="userInfo && userInfo.everyFirstRechargeState == 'No'">
                                                +{{ item.handselRate > item.everyHandselRate ? (item.handselRate ||
                                                    0).toFixed(2) : (item.everyHandselRate || 0).toFixed(2) }}
                                            </span>
                                            <span v-else>{{ (item.everyHandselRate || 0).toFixed(2) }}</span>
                                            <span>%</span>
                                        </div>
                                        <div class="extra"
                                            v-else-if="item.everyHandselRate || (userInfo && userInfo.everyFirstRechargeState == 'No' && item.handselRate) || (userInfo && userInfo.firstRechargeState == 'No' && item.firstHandselRate)">
                                            <div
                                                v-if="userInfo && userInfo.firstRechargeState == 'No' && item.firstHandselRate">
                                                +{{ item.handselRate + item.firstHandselRate + item.everyHandselRate }}%
                                            </div>
                                            <div
                                                v-else-if="userInfo && userInfo.everyFirstRechargeState == 'No' && item.handselRate">
                                                +{{ item.handselRate + item.everyHandselRate }}%
                                            </div>
                                            <div v-else>+{{ (item.everyHandselRate || 0).toFixed(2) }}%</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <a-button class="submit-btn" @click="handleRecharge">
                                {{ $t("deposit.Deposit") }}
                            </a-button>
                            <div v-if="rulesDesc" class="rulesDesc" style="margin-top: .15rem;">
                                <div v-html="rulesDesc"></div>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </van-popup>
        <depositRulesPopup ref="depositRulesPopup"></depositRulesPopup>
        <depositDetail ref="depositDetail" :details="depositDetails" @onContinue="onContinueDeposit"></depositDetail>
        <tipsPopup ref="depositTipsPop" :tips="$t('deposit.DepositTips2')"></tipsPopup>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import { getRechargeList, createMemberRechargeOrder, getRate, createMemberUsdtRechargeOrder, getUsdtTutorials, getUsdtRechargeOrderByWaitPay } from '@/api/deposit'
import { getUserInfo } from '@/api/user'
import { Toast } from 'vant'
import depositRulesPopup from './depositRulesPopup.vue'
import depositDetail from './depositDetail.vue'
import statusBar from '@/components/statusBar'
import tipsPopup from './tipsPopup.vue'
export default {
    name: 'depositPopup',
    components: { depositRulesPopup, depositDetail, statusBar, tipsPopup },
    data() {
        return {
            depositType: 'OnlineDeposit',
            isShowPayList: false,
            rechargeGiftType: '',
            RechargeList: [],
            gratuityConditions: '',
            rulesDesc: '',
            totalPayList: [],
            payList: [],
            payListId: '',
            payListImg: '',
            payChannelType: '',
            payListName: '',
            rechargeValue: '',
            minRechargeAmt: 0,
            maxRechargeAmt: 0,
            rewardPercentage: 0,
            rewardPercentageAmount1: '',
            rewardPercentageAmount2: '',
            rewardPercentageAmount3: '',
            rewardPercentageTxt1: '',
            rewardPercentageTxt2: '',
            rewardPercentageTxt3: '',
            rewardPercentage1: '',
            rewardPercentage2: '',
            rewardPercentage3: '',
            showBonus: true,
            userInfo: {},
            progress: 0,
            loading: false,
            canCallHandleRecharge: true,

            changeRate: 0,
            usdtRechargeValue: '',
            usdtRechargeGiftType: '',
            usdtGratuityConditions: '',
            usdtList: [],
            depositDetails: {}
        }
    },
    computed: {
        ...mapState({
            device: state => state.app.device
        }),
        classObj() {
            return {
                mobile: this.device === 'mobile'
            }
        },
        currency() {
            const currency = this.$store.getters.sysConfig?.currency
            return currency || ''
        },
        rateMoney() {
            return (Number(this.changeRate || 0) * Number(this.usdtRechargeValue || 0)).toFixed(2)
        },
        rateBonus() {
            return (Number(this.changeRate || 0) * Number(this.rewardPercentage || 0)).toFixed(2)
        },
        show: {
            get() {
                return this.$store.getters.isShowDepositPopup
            },
            set(val) {
                return val
            }
        }
    },
    created() { },
    methods: {
        close() {
            this.$store.dispatch('user/setIsShowDepositPopup', false)
        },
        open() {
            this.$nextTick(() => {
                this.$refs.depositTipsPop.open()
            })
            getUserInfo().then(res1 => {
                if (res1.code) return
                this.userInfo = res1.result
                const grandRechargeNumber = Number(this.userInfo.grandRechargeNumber) + 1

                getRechargeList().then(res => {
                    if (res.code) {
                        Toast(res.message)
                        return
                    }
                    const D = res.result

                    this.RechargeList = []
                    this.rechargeGiftType = D.rechargeGiftType
                    if (this.rechargeGiftType === 'firstRecharge') {
                        this.RechargeList = D.firstRechargeDetailVoList
                        this.gratuityConditions = D.gratuityConditions
                    }
                    if (this.rechargeGiftType === 'repeatRecharge') {
                        this.RechargeList = D.everyRepeatRechargeDetailVoList
                        if (D && D.repeatRechargeNumberDetailVoList.length) {
                            if (D.repeatRechargeNumberDetailVoList.some(x => Number(x.rechargeNumber) === Number(grandRechargeNumber))) {
                                this.RechargeList = D.repeatRechargeNumberDetailVoList.filter(x => Number(x.rechargeNumber) === Number(grandRechargeNumber))[0].repeatRechargeDetailVoList
                            }
                        }
                    }

                    this.usdtList = []
                    this.usdtRechargeGiftType = D.usdtRechargeGiftType
                    if (this.usdtRechargeGiftType === 'firstRecharge') {
                        this.usdtList = D.usdtFirstRechargeDetailVoList
                        this.usdtGratuityConditions = D.usdtGratuityConditions
                    }
                    if (this.usdtRechargeGiftType === 'repeatRecharge') {
                        this.usdtList = D.usdtEveryRepeatRechargeDetailVoList
                        if (D && D.usdtRepeatRechargeNumberDetailVoList.length) {
                            if (D.usdtRepeatRechargeNumberDetailVoList.some(x => Number(x.rechargeNumber) === Number(grandRechargeNumber))) {
                                this.usdtList = D.usdtRepeatRechargeNumberDetailVoList.filter(x => Number(x.rechargeNumber) === Number(grandRechargeNumber))[0].repeatRechargeDetailVoList
                            }
                        }
                    }

                    this.totalPayList = D.payList.filter(x => x.enableRechargeType === 1).sort((a, b) => b.sort - a.sort)
                    this.handlePayListItemClick(this.totalPayList[0])
                    this.rulesDesc = D.explainDec
                    this.getRewardPercentage()
                })
            })
        },
        handlePayListItemClick(item) {
            this.payListId = item.id
            this.payChannelType = item.payChannelType
            this.payListImg = item.imgUrl
            this.payListName = item.name
            this.minRechargeAmt = item.minRechargeAmt || 0
            this.maxRechargeAmt = item.maxRechargeAmt || 0
            if (item.payChannelType.indexOf('USDT') !== -1) {
                this.getRate()
                getUsdtRechargeOrderByWaitPay().then((res) => {
                    if (res.code) return
                    const D = res.result
                    this.onShowDetail(D)
                })
                this.depositType = 'TransferDeposit'
            } else {
                this.depositType = 'OnlineDeposit'
            }
        },
        getRate() {
            getRate().then(res => {
                if (res.code) return
                this.changeRate = res.result
            })
        },
        getRewardPercentage() {
            let rechargeValue = 0
            let list = []

            if (this.depositType === 'OnlineDeposit') {
                rechargeValue = this.rechargeValue
                list = this.RechargeList || []
            }
            if (this.depositType === 'TransferDeposit') {
                rechargeValue = this.usdtRechargeValue
                list = this.usdtList || []
            }
            if (Number(rechargeValue) < Number(this.rewardPercentageAmount1)) {
                this.rewardPercentageTxt1 = ''
                this.rewardPercentage1 = ''
            }
            if (Number(rechargeValue) < Number(this.rewardPercentageAmount2)) {
                this.rewardPercentageTxt2 = ''
                this.rewardPercentage2 = ''
            }
            if (Number(rechargeValue) < Number(this.rewardPercentageAmount3)) {
                this.rewardPercentageTxt3 = ''
                this.rewardPercentage3 = ''
            }
            if (!list.length) {
                this.rewardPercentage = 0
                return
            }
            if (rechargeValue < list[0].rechargeAmt) {
                this.rewardPercentage = 0
                return
            }
            if (!this.rewardPercentage1 && !this.rewardPercentage2 && !this.rewardPercentage3) {
                this.rewardPercentage = 0
            }
            for (let i = 0; i < list.length; i++) {
                if (Number(rechargeValue) >= Number(list[i].rechargeAmt)) {
                    if ((i + 1) < list.length) {
                        if (Number(rechargeValue) <= Number(list[i + 1].rechargeAmt)) {
                            let hasEveryReward = 0
                            let hasReward = 0
                            let hasFirstReward = 0
                            let j = Number(i)
                            for (j; j >= 0; j--) {
                                if (!hasEveryReward && list[j].everyHandselRate) {
                                    hasEveryReward = list[j].everyHandselRate
                                    this.getRP(list[j], 'EveryReward')
                                }
                                if (!hasReward && list[j].handselRate) {
                                    hasReward = list[j].handselRate
                                    this.getRP(list[j], 'Reward')
                                }
                                if (!hasFirstReward && list[j].firstHandselRate) {
                                    hasFirstReward = list[j].firstHandselRate
                                    this.getRP(list[j], 'FirstReward')
                                }
                            }
                        }
                    } else {
                        let hasEveryReward = 0
                        let hasReward = 0
                        let hasFirstReward = 0
                        let j = Number(i)
                        for (j; j >= 0; j--) {
                            if (!hasEveryReward && list[j].everyHandselRate) {
                                hasEveryReward = list[j].everyHandselRate
                                this.getRP(list[j], 'EveryReward')
                            }
                            if (!hasReward && list[j].handselRate) {
                                hasReward = list[j].handselRate
                                this.getRP(list[j], 'Reward')
                            }
                            if (!hasFirstReward && list[j].firstHandselRate) {
                                hasFirstReward = list[j].firstHandselRate
                                this.getRP(list[j], 'FirstReward')
                            }
                        }
                    }
                }
            }
        },
        getRP(x, type) {
            let rechargeValue = 0
            let gratuityConditions = 'TOTAL'
            if (this.depositType === 'OnlineDeposit') {
                rechargeValue = this.rechargeValue
                gratuityConditions = this.gratuityConditions
            }
            if (this.depositType === 'TransferDeposit') {
                rechargeValue = this.usdtRechargeValue
                gratuityConditions = this.usdtGratuityConditions
            }
            this.rewardPercentage = ''
            if (this.userInfo && this.userInfo.firstRechargeState === 'No') {
                if (x.everyHandselRate && type === 'EveryReward') {
                    this.rewardPercentageTxt1 = this.$t('deposit.rewardPercentageTxt1_1') + x.rechargeAmt + this.$t('deposit.rewardPercentageTxt1_2') + x.everyHandselRate + this.$t('deposit.rewardPercentageTxt1_3')
                    this.rewardPercentage1 = x.everyHandselRate
                    this.rewardPercentageAmount1 = x.rechargeAmt
                }
                if (x.handselRate && type === 'Reward') {
                    this.rewardPercentageTxt2 = this.$t('deposit.rewardPercentageTxt2_1') + x.rechargeAmt + this.$t('deposit.rewardPercentageTxt2_2') + x.handselRate + this.$t('deposit.rewardPercentageTxt2_3')
                    this.rewardPercentage2 = x.handselRate
                    this.rewardPercentageAmount2 = x.rechargeAmt
                }
                if (x.firstHandselRate && type === 'FirstReward') {
                    this.rewardPercentageTxt3 = this.$t('deposit.rewardPercentageTxt3_1') + x.rechargeAmt + this.$t('deposit.rewardPercentageTxt3_2') + x.firstHandselRate + this.$t('deposit.rewardPercentageTxt3_3')
                    this.rewardPercentage3 = x.firstHandselRate
                    this.rewardPercentageAmount3 = x.rechargeAmt
                }
            } else if (this.userInfo && this.userInfo.everyFirstRechargeState === 'No') {
                if (x.everyHandselRate && type === 'EveryReward') {
                    this.rewardPercentageTxt1 = this.$t('deposit.rewardPercentageTxt1_1') + x.rechargeAmt + this.$t('deposit.rewardPercentageTxt1_2') + x.everyHandselRate + this.$t('deposit.rewardPercentageTxt1_3')
                    this.rewardPercentage1 = x.everyHandselRate
                    this.rewardPercentageAmount1 = x.rechargeAmt
                }
                if (x.handselRate && type === 'Reward') {
                    this.rewardPercentageTxt2 = this.$t('deposit.rewardPercentageTxt2_1') + x.rechargeAmt + this.$t('deposit.rewardPercentageTxt2_2') + x.handselRate + this.$t('deposit.rewardPercentageTxt2_3')
                    this.rewardPercentage2 = x.handselRate
                    this.rewardPercentageAmount2 = x.rechargeAmt
                }
            } else {
                if (x.everyHandselRate && type === 'EveryReward') {
                    this.rewardPercentageTxt1 = this.$t('deposit.rewardPercentageTxt1_1') + x.rechargeAmt + this.$t('deposit.rewardPercentageTxt1_2') + x.everyHandselRate + this.$t('deposit.rewardPercentageTxt1_3')
                    this.rewardPercentage1 = x.everyHandselRate
                    this.rewardPercentageAmount1 = x.rechargeAmt
                }
            }
            if (gratuityConditions === 'MAXIMUM') {
                if (this.userInfo && this.userInfo.firstRechargeState === 'No') {
                    if (Number(x.firstHandselRate) >= Number(x.handselRate) && Number(x.firstHandselRate) >= Number(x.everyHandselRate)) {
                        this.rewardPercentage = parseInt(Number(rechargeValue || 0) / 100 * Number(x.firstHandselRate || 0))
                    }
                    if (Number(x.handselRate) >= Number(x.firstHandselRate) && Number(x.handselRate) >= Number(x.everyHandselRate)) {
                        this.rewardPercentage = parseInt(Number(rechargeValue || 0) / 100 * Number(x.handselRate || 0))
                    }
                    if (Number(x.everyHandselRate) >= Number(x.handselRate) && Number(x.everyHandselRate) >= Number(x.firstHandselRate)) {
                        this.rewardPercentage = parseInt(Number(rechargeValue || 0) / 100 * Number(x.everyHandselRate || 0))
                    }
                } else if (this.userInfo && this.userInfo.everyFirstRechargeState === 'No') {
                    if (Number(x.handselRate) >= Number(x.everyHandselRate)) {
                        this.rewardPercentage = parseInt(Number(rechargeValue || 0) / 100 * Number(x.handselRate || 0))
                    }
                    if (Number(x.everyHandselRate) >= Number(x.handselRate)) {
                        this.rewardPercentage = parseInt(Number(rechargeValue || 0) / 100 * Number(x.everyHandselRate || 0))
                    }
                } else {
                    this.rewardPercentage = parseInt(Number(rechargeValue || 0) / 100 * Number(x.everyHandselRate || 0))
                }
            } else {
                if (this.rewardPercentage1) {
                    this.rewardPercentage = Number(this.rewardPercentage) + parseInt(Number(rechargeValue || 0) / 100 * Number(this.rewardPercentage1 || 0))
                }
                if (this.rewardPercentage2) {
                    this.rewardPercentage = Number(this.rewardPercentage) + parseInt(Number(rechargeValue || 0) / 100 * Number(this.rewardPercentage2 || 0))
                }
                if (this.rewardPercentage3) {
                    this.rewardPercentage = Number(this.rewardPercentage) + parseInt(Number(rechargeValue || 0) / 100 * Number(this.rewardPercentage3 || 0))
                }
            }
        },
        handleRechargeChange() {
            this.getRewardPercentage()
        },
        handleListItemClick(item) {
            this.rechargeValue = item.rechargeAmt
            this.getRewardPercentage()
        },
        handleUsdtListItemClick(item) {
            this.usdtRechargeValue = item.rechargeAmt
            this.getRewardPercentage()
        },
        handleRecharge() {
            if (!this.payListId) return
            if (this.canCallHandleRecharge) {
                this.canCallHandleRecharge = false
                if (!this.rechargeValue) {
                    this.canCallHandleRecharge = true
                    Toast(this.$t('deposit.PleaseEnterDeposit'))
                    return
                }
                if (this.minRechargeAmt && Number(this.rechargeValue || 0) < Number(this.minRechargeAmt || 0)) {
                    this.canCallHandleRecharge = true
                    Toast(this.$t('deposit.isMinRechargeAmtTips'))
                    return
                }
                if (this.maxRechargeAmt && Number(this.rechargeValue || 0) > Number(this.maxRechargeAmt || 0)) {
                    this.canCallHandleRecharge = true
                    Toast(this.$t('deposit.isMaxRechargeAmtTips'))
                    return
                }
                const userAgent = navigator.userAgent
                const params = {
                    payChannelId: this.payListId,
                    orderAmt: this.rechargeValue,
                    yesNoType: this.showBonus ? 'Yes' : 'No',
                    userAgent
                }
                createMemberRechargeOrder(params, { custom: { toast: false } })
                    .then(res => {
                        if (res.code === 0 && !this.canCallHandleRecharge) {
                            this.close()
                            this.canCallHandleRecharge = true
                            const url = res.result.payUrl
                            if (this.payChannelType === 'SupefinaPay') {
                                window.location.href = url
                            } else if (this.payChannelType === 'TurCodePay') {
                                window.location.href = url
                            } else {
                                const encodedUrl = encodeURIComponent(url)
                                this.onNavTo({ url: '/depositWv', type: 'get', params: { url: encodedUrl } })
                            }
                        }
                        if (res.code !== 0) {
                            this.canCallHandleRecharge = true
                        }
                    }).catch(() => {
                        this.canCallHandleRecharge = true
                    }).finally(() => {
                        this.canCallHandleRecharge = true
                    })
            }
        },
        handleUsdtRecharge() {
            if (!this.payListId) return
            if (this.canCallHandleRecharge) {
                this.canCallHandleRecharge = false
                if (!this.usdtRechargeValue) {
                    this.canCallHandleRecharge = true
                    Toast(this.$t('deposit.PleaseEnterDeposit'))
                    return
                }
                if (this.minRechargeAmt && Number(this.usdtRechargeValue || 0) < Number(this.minRechargeAmt || 0)) {
                    this.canCallHandleRecharge = true
                    Toast(this.$t('deposit.isMinRechargeAmtTips'))
                    return
                }
                if (this.maxRechargeAmt && Number(this.usdtRechargeValue || 0) > Number(this.maxRechargeAmt || 0)) {
                    this.canCallHandleRecharge = true
                    Toast(this.$t('deposit.isMaxRechargeAmtTips'))
                    return
                }
                const userAgent = navigator.userAgent
                const params = {
                    payChannelId: this.payListId,
                    usdtOrderAmt: this.usdtRechargeValue,
                    yesNoType: this.showBonus ? 'Yes' : 'No',
                    userAgent
                }
                createMemberUsdtRechargeOrder(params)
                    .then(res => {
                        if (res.code === 0 && !this.canCallHandleRecharge) {
                            this.canCallHandleRecharge = true
                            this.onShowDetail(res.result)
                        }
                        if (res.code !== 0) {
                            Toast(res.message)
                            this.canCallHandleRecharge = true
                        }
                    }).catch(() => {
                        this.canCallHandleRecharge = true
                    }).finally(() => {
                        this.canCallHandleRecharge = true
                    })
            }
        },
        onShowDetail(item) {
            this.$nextTick(() => {
                this.depositDetails = item
                this.$refs.depositDetail.open()
            })
        },
        onContinueDeposit() {
            this.depositType = 'TransferDeposit'
        },
        toTutorial() {
            if (this.depositType === 'TransferDeposit') {
                getUsdtTutorials().then((res) => {
                    if (!res) return
                    if (res.code) return
                    const D = res.result
                    const obj = { eventName: this.$t('deposit.Tutorial'), eventContent: D }
                    const params = encodeURIComponent(JSON.stringify(obj))
                    const timestamp = new Date().getTime()
                    this.onNavTo({ url: 'PromotionCustom', type: 'post', params: { timestamp, params } })
                    this.close()
                })
            }
        },
        toTransaction() {
            this.$store.dispatch('user/setIsShowTransactionPopup', true)
        }
    }
}
</script>

<style scoped lang="scss">
@import '@/common/styles/viriables.scss';

.popup {
    .van-popup {
        background: transparent;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;

        .content {
            background: $bg-popup-color;
            border-radius: .2rem;
            box-sizing: border-box;
            overflow: hidden;
            height: 686px;
            position: relative;
            width: 600px;
            display: flex;
            flex-direction: column;

            .header {
                display: flex;
                height: 1rem;
                padding-left: .46rem;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding-top: .1rem;

                &>div:first-child {
                    font-size: .32rem;
                    font-weight: bold;
                    color: #fff;
                    padding-top: .28rem;
                }

                .close {
                    color: $font-iconfont-color;
                    font-size: .36rem;
                    font-weight: bold;
                    height: 1rem;
                    width: 1rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                }
            }

            .DepositMethods-list {
                display: flex;
                align-items: center;
                gap: 8px;
                margin: 0 .42rem;
                flex-wrap: wrap;

                .DepositMethods-item {
                    width: calc(50% - 4px);
                    border-radius: .12rem;
                    background: linear-gradient(163deg, #16191E, #3B404C);
                    border-style: solid;
                    border-width: 1px;
                    border-color: #7E848B;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    height: 1.8rem;
                    padding: .1rem;
                    cursor: pointer;

                    &.act {
                        border-color: $font-theme-color;
                        background: linear-gradient(0deg, #838182, #4C4F51, #323538);
                    }

                    &>img {
                        height: auto;
                        height: 100%;
                        width: 100%;
                        box-sizing: border-box;
                        border-radius: .05rem;
                        background: rgba(255, 255, 255, .1);
                    }

                    &>div {
                        color: #fff;
                        font-size: 12px;
                        font-weight: 500;
                    }
                }
            }

            .bouns-box {
                margin: .24rem 0;
                background: rgba(255, 255, 255, .1);
                padding: .2rem;
                border-radius: .12rem;

                &.act {
                    background: rgba(255, 120, 45, 0.08);

                    .bouns-box-header {
                        border-color: rgb(255, 120, 45);
                    }
                }

                .bouns-box-header {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    border: .01rem solid #aaa;
                    padding: .2rem;
                    border-radius: .12rem;

                    &>div:first-child {
                        padding-left: .9rem;
                        position: relative;
                        font-size: .28rem;
                        color: #fff;

                        &>img {
                            width: 1.2rem;
                            height: 1.2rem;
                            position: absolute;
                            top: calc(50% - .6rem);
                            left: -.24rem;
                        }

                    }
                }
            }

            .Deposit-main {
                padding: 0 .42rem;
                padding-bottom: 30px;
                width: 100%;
                flex: 1;
                overflow: auto;

                .DepositContent {
                    position: relative;
                    z-index: 2;
                }

                .amount_input {
                    .Recharge-Limt {
                        color: $font-sub-color;
                        font-size: 14px;
                        text-align: left;
                    }

                    ::v-deep input::-webkit-outer-spin-button {
                        -webkit-appearance: none;
                    }

                    ::v-deep input::-webkit-inner-spin-button {
                        -webkit-appearance: none;
                    }

                    ::v-deep input[type='number'] {
                        -moz-appearance: textfield;
                    }

                    ::v-deep .ant-input {
                        background-color: $bg-input-color !important;
                        border-radius: 0.14rem;
                        font-size: 0.22rem;
                        height: 0.85rem;
                        padding: 0px 0.24rem !important;
                        color: #e3e3e3;
                        border: .02rem solid $border-input-color !important;
                        padding-right: 2.8rem !important;

                        &:focus {
                            background-color: $bg-input-act-color;
                            border-color: $border-active-color !important;
                        }

                        &::-webkit-input-placeholder {
                            /* WebKit browsers */
                            font-size: .22rem;
                            color: $font-placeholder-color;
                            opacity: .7;
                            font-weight: 100;
                        }

                        &:-moz-placeholder {
                            /* Mozilla Firefox 4 to 18 */
                            font-size: .22rem;
                            color: $font-placeholder-color;
                        }

                        &::-moz-placeholder {
                            /* Mozilla Firefox 19+ */
                            font-size: .22rem;
                            color: #516382;
                        }

                        &:-ms-input-placeholder {
                            /* Internet Explorer 10+ */
                            font-size: .22rem;
                            color: $font-placeholder-color;
                        }

                    }

                    ::v-deep .ant-input-suffix {
                        .extra {
                            color: #ff7300;
                            padding-right: .24rem;
                        }

                        .noBonus {
                            color: #f3df00;
                            padding-right: .24rem;
                        }
                    }
                }

                .exchangeRate {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    padding: .08rem 0;

                    .exchangeRate_left {
                        color: #666;
                        font-size: .22rem;
                        line-height: .3rem;
                        text-align: left;

                        &>div:last-child {
                            color: #fff;
                            display: flex;
                            align-items: center;
                            font-size: .22rem;
                            text-align: left;

                            &>span:last-child {
                                color: #D1AE52;
                                font-size: .3rem;
                                margin-left: .12rem;
                            }
                        }
                    }

                    .exchangeRate_right {
                        text-align: right;
                        color: #FFAA09;
                        font-weight: 700;
                        font-size: .24rem;
                    }
                }

                .form_gap {
                    display: flex;
                    gap: 15px;
                    cursor: pointer;
                    position: relative;
                    margin: .38rem 0;
                    flex-wrap: wrap;
                    margin-top: .5rem;

                    .form_gap_item {
                        width: calc(33.33333% - 11px);
                        border-radius: .12rem;
                        box-sizing: border-box;
                        font-size: .24rem;
                        border: .01rem solid #7E848B;
                        background: linear-gradient(163deg, #16191E, #3B404C);
                        padding: .1rem 0;
                        padding-top: .14rem;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex-direction: column;
                        height: 1rem;
                        position: relative;

                        &.act {
                            border: .01rem solid #7E848B;
                            background: linear-gradient(0deg, #838182, #4C4F51, #323538);
                        }

                        .num {
                            color: #fff;
                            font-weight: bold;
                            font-size: .3rem;
                            line-height: .32rem;
                        }

                        .extra {
                            position: absolute;
                            z-index: 1;
                            top: -.2rem;
                            right: 0;
                            font-size: .2rem;
                            height: .3rem;
                            line-height: .32rem;
                            padding: 0 .2rem;
                            background-color: #BC272F;
                            border-radius: .2rem .2rem .2rem 0;
                            color: #F7D515;

                            &:after {
                                content: '';
                                position: absolute;
                                left: 0;
                                bottom: -.08rem;
                                border-bottom: .1rem solid transparent;
                                border-left: .1rem solid #BC272F;
                                border-right: .1rem solid transparent;
                                height: 0;
                                width: 0;
                            }
                        }
                    }
                }

                .submit-btn {
                    width: 100%;
                    font-size: .24rem;
                    height: .65rem;
                    text-align: center;
                    line-height: .65rem;
                    background: $bg-btn-color;
                    color: $font-btn-color;
                    font-weight: bold;
                    border-radius: .14rem;
                    cursor: pointer;

                    &.ant-btn {
                        border-color: transparent;
                    }

                    &.toTransaction {
                        background: #C64707;
                        margin-top: .2rem;
                        margin-bottom: .1rem;
                    }
                }

                .tips {
                    color: #888;
                    font-size: .22rem;
                    line-height: .38rem;
                    margin-top: 0.03rem;
                    text-align: left;
                }
            }

            .rulesDesc {
                color: $font-sub-color;
                background: $bg-menu-color;
                border-radius: 12px;
                width: 100%;
                padding: 10px;
                box-sizing: border-box;
                text-align: left;

                ::v-deep table {
                    width: 100% !important;
                    border-collapse: collapse;
                    /* 使得边框合并为单一边框 */
                }

                ::v-deep table {
                    border: 1px solid #888;
                    /* 设置表格、表头、单元格的边框 */
                }

                ::v-deep th {
                    border: 1px solid #888;
                    /* 设置表格、表头、单元格的边框 */
                }

                ::v-deep td {
                    border: 1px solid #888;
                    /* 设置表格、表头、单元格的边框 */
                }

                ::v-deep td {
                    // padding: 8upx 15upx ;
                }
            }
        }
    }

    &.mobile {
        // width: 100% !important;
        // height: 100% !important;

        .van-popup {
            &.van-popup--center {
                top: 0;
                left: 0;
                transform: translate3d(0, 0, 0);
                width: 100% !important;
                height: 100% !important;
            }
        }

        .content {
            width: 100% !important;
            height: 100% !important;
            padding-left: 0 !important;
            background: $bg-color;

            .Deposit {
                width: 100% !important;

                .header {
                    padding-left: .24rem !important;
                }

                .Deposit-main {
                    padding: 0 .24rem !important;
                    // background: #060508 !important;
                    position: relative;
                    z-index: 2;
                }
            }
        }
    }
}</style>
