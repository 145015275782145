<template>
    <div>
        <div class="main_content">
            <div class="WP_form">
                <!-- 银行编号类型 -->
                <div class="info_title" style="margin-bottom: 6upx;">{{ $t('bankset.Type') }}</div>
                <div class="info_radio">
                    <van-radio-group v-model="formData.bankCode" direction="horizontal">
                        <van-radio :name="item.value" checked-color="#FFB000" v-for="(item, index) in columns"
                            :key="index">{{ item.label }}</van-radio>
                    </van-radio-group>
                </div>
                <!-- 收款人名称 -->
                <div class="info_title">{{ $t('bankset.AccountName') }}</div>
                <div class="info_input">
                    <a-input v-model="formData.accountName" :placeholder="$t('bankset.NameOfTheAccountHolder')"
                        allowClear></a-input>
                </div>
                <!-- 收款账号 -->
                <div class="info_title">{{ $t('bankset.PayeeAccount') }}</div>
                <div class="info_input">
                    <a-input v-model="formData.accountPayee" :placeholder="$t('bankset.PayeeAccount')" allowClear></a-input>
                    <div v-if="formData.bankCode == 'CARD'" class="tips">{{ $t('lang.tips') + '：'}}{{ $t('bankset.PayeeAccountTips2') }}</div>
                    <div v-else class="tips">{{ $t('lang.tips') + '：'}}{{ $t('bankset.PayeeAccountTips1') }}</div>
                    <div class="tips" style="color: red;">{{ $t("deposit.DepositTips2") }}</div>
                </div>
                <!-- 银行编号 -->
                <!-- <div class="info_title">{{ $t('bankset.BankName') }}</div>
                <div class="info_input" @click="onShowChannelPopup">
                    <a-input v-model="formData.sbpBankCode" disabled :placeholder="$t('bankset.ChooseBank')" allowClear
                        style="pointer-events: none;">
                        <div slot="suffix" class="iconfont icon-xiangyou"></div>
                    </a-input>
                </div> -->
                <div class="info_title">{{ $t('bankset.WithdrawPassword') }}</div>
                <div class="info_input">
                    <a-input-password v-model="formData.withdrawPassword"
                        :placeholder="$t('bankset.InputWithdrawalPassword')" autocomplete="new-password"
                        allowClear></a-input-password>
                </div>
                <div class="info_title">{{ $t('bankset.ConfirmWithdrawalPassword') }}</div>
                <div class="info_input">
                    <a-input-password v-model="formData.withdrawPasswordVerify"
                        :placeholder="$t('bankset.InputConfirmWithdrawalPassword')" autocomplete="new-password"
                        allowClear></a-input-password>
                </div>
            </div>
            <div class="btn_box">
                <div class="action_btn" @click="handleSubmit">
                    <span>{{ $t('bankset.Save') }}</span>
                </div>
            </div>
        </div>
        <channelPopup ref="channelPopup" :name="formData.sbpBankCode" @onConfirm="onChannelPopupConfirm">
        </channelPopup>
    </div>
</template>

<script>
import { Toast } from 'vant'
import { deepClone } from '@/utils'
import {
    saveBankRussia,
    getBankRussia,
    updateBankRussia
} from '@/api/withdraw'
import channelPopup from './modules/channelPopup.vue'
import md5 from 'md5'
export default {
    name: 'banksetRussia',
    components: { channelPopup },
    data() {
        return {
            show: false,
            columns: [
                // { value: 'CARD', label: 'CARD' },
                { value: 'SBP', label: 'SBP' }
                // { value: 'QIWI', label: 'QIWI' }
            ],
            formData: {
                bankCode: 'SBP',
                accountName: '',
                accountPayee: '',
                sbpBankCode: '',
                withdrawPassword: '',
                withdrawPasswordVerify: ''
            }
        }
    },
    created() { },
    methods: {
        close() {
            this.$emit('close')
        },
        async open() {
            this.formData = {
                bankCode: 'SBP',
                accountName: '',
                accountPayee: '',
                sbpBankCode: '',
                withdrawPassword: '',
                withdrawPasswordVerify: ''
            }
            getBankRussia().then(res => {
                if (res.code) return
                const D = res.result
                this.formData = {
                    ...D,
                    withdrawPassword: ''
                }
            })
            this.$nextTick(() => { })
        },
        onShowChannelPopup() {
            this.$refs.channelPopup.open()
        },
        onChannelPopupConfirm(e) {
            this.formData.sbpBankCode = e
            this.$forceUpdate()
        },
        async handleSubmit() {
            if (!this.formData.bankCode) {
                Toast(this.$t('bankset.ChooseCertificateType'))
                return
            }
            if (!this.formData.accountName) {
                Toast(this.$t('bankset.InputAccountName'))
                return
            }
            if (!this.formData.accountPayee) {
                Toast(this.$t('bankset.InputPayeeAccount'))
                return
            } else {
                this.formData.accountPayee = this.formData.accountPayee.trim()
            }
            // if (!this.formData.sbpBankCode) {
            //     Toast(this.$t('bankset.PleaseSelectBank'))
            //     return
            // }
            if (!this.formData.withdrawPasswordVerify) {
                Toast(this.$t('bankset.InputConfirmWithdrawalPassword'))
                return
            }
            if (this.formData.withdrawPassword !== this.formData.withdrawPasswordVerify) {
                Toast(this.$t('bankset.InputInconsistentWithdrawPassword'))
                return
            }
            const form = deepClone(this.formData)
            if (form.withdrawPassword) form.withdrawPassword = md5(form.withdrawPassword)
            if (form.withdrawPasswordVerify) form.withdrawPasswordVerify = md5(form.withdrawPasswordVerify)
            let result
            if (form.id) {
                result = await updateBankRussia(form)
            } else {
                result = await saveBankRussia(form)
            }
            Toast(result.message)
            if (result.code) return
            this.$emit('succ')
            this.close()
        }
    }
}
</script>

<style scoped lang="scss">
@import '@/common/styles/viriables.scss';
.main_content {
    overflow: auto;

    .WP_form {
        padding: 0 .28rem;

        .info_title {
            margin-bottom: .18rem;
            font-size: .28rem;
            color: $font-title-color;
            margin-top: .24rem;
            text-align: left;
        }

        .info_radio {
            width: 100%;
            font-size: .26rem;
            font-weight: 400;
            padding-right: .28rem;
            display: flex;
            align-items: center;
            justify-content: space-between;
            position: relative;

            ::v-deep .van-radio {
                margin-bottom: .18rem;

                .van-radio__label {
                    color: $font-label-color;
                }

                .van-radio__icon--checked {
                    &+.van-radio__label {
                        color: #fff;
                    }
                }
            }
        }

        .info_input {
            ::v-deep input::-webkit-outer-spin-button {
                -webkit-appearance: none;
            }

            ::v-deep input::-webkit-inner-spin-button {
                -webkit-appearance: none;
            }

            ::v-deep input[type='number'] {
                -moz-appearance: textfield;
            }

            ::v-deep .ant-input-suffix {
                .ant-input-clear-icon {
                    color: $font-iconfont-color;
                    font-size: .32rem;
                    margin-right: .1rem;
                }

                .icon-xiangyou {
                    margin-right: .1rem;
                    font-size: .3rem;
                    color: $font-iconfont-color;
                }
            }

            ::v-deep .ant-input-password {
                .ant-input {
                    padding-right: 1rem;
                }

                .ant-input-suffix {
                    .ant-input-password-icon {
                        margin-right: .1rem;
                        font-size: .3rem;
                        color: $font-iconfont-color;
                    }
                }
            }

            ::v-deep .ant-input {
                background: $bg-input-color !important;
                border-radius: 0.14rem;
                font-size: 0.22rem;
                height: .8rem;
                padding: 0px 0.24rem !important;
                color: #e3e3e3;
                border: .02rem solid $border-input-color !important;
                padding-left: .28rem !important;

                &:focus {
                    background: $bg-input-act-color !important;
                    border-color: $border-active-color !important;
                }

                &::-webkit-input-placeholder {
                    /* WebKit browsers */
                    font-size: .22rem;
                    color: $font-placeholder-color;
                    opacity: .7;
                    font-weight: 100;
                }

                &:-moz-placeholder {
                    /* Mozilla Firefox 4 to 18 */
                    font-size: .22rem;
                    color: $font-placeholder-color;
                }

                &::-moz-placeholder {
                    /* Mozilla Firefox 19+ */
                    font-size: .22rem;
                    color: $font-placeholder-color;
                }

                &:-ms-input-placeholder {
                    /* Internet Explorer 10+ */
                    font-size: .22rem;
                    color: $font-placeholder-color;
                }
            }

            .tips {
                color: #888;
                font-size: .22rem;
                text-align: left;
                padding-top: .04rem;
                // padding-left: .28rem;
            }
        }
    }

    .btn_box{
        margin: .3rem .28rem;
    }
    .action_btn {
        // width: 96%;
        height: .7rem;
        border-radius: .15rem;
        // margin: .3rem auto;
        margin-top: .58rem;
        font-family: eryawenrunti;
        font-weight: 600;
        font-size: .28rem;
        color: #fff;
        text-align: center;
        line-height: .7rem;
        cursor: pointer;
        background-color: #286aff;
        margin-bottom: 0;

        &.disabled {
            background: #9D9E9F;
            color: rgba(255, 255, 255, 0.45);
            cursor: not-allowed;
            box-shadow: 0 .06rem 0 0 #2D3241, 0 .02rem .03rem 0 #333, 0 .02rem .03rem 0 #999;
        }
    }

    .tips {
        color: red;
        font-size: .2rem;
        text-align: left;
        padding-top: .04rem;
        font-weight: bold;
        // padding-left: .28rem;
    }
}
</style>
