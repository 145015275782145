export const domainKey = 'luckycat7c'
export const domainName = `www.${domainKey}.com`
// export const domainName = 'dev-russia.alistar777.com'
// export const domainName = 'dev.morena888.com'
// export const domainName = 'dev.leo-pp.com'
export const host = `https://${domainName}/api` // 正式
// export const host = 'http://dev.rei-bet.com/api' // 测试
// export const host = "http://8.218.60.59:9202" // 测试
export const inviteCodeKey = 'luckycat'
export const domain = `https://${domainName}`
export const webSocketUrl = `wss://${domainName}/api/onlineSend`
